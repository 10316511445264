import { BeyondEMRConstants } from './beyondemrconstants';
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable()
export class AuthTokenHelper {
  private encryptionTokenKey: string;
  private storageTokenName: string;
  public result: boolean;

  public constructor() {
    this.storageTokenName = CryptoJS.AES.encrypt(BeyondEMRConstants.LocalStorageAuthorizationToken, BeyondEMRConstants.EncryptionTokenKey).ciphertext;
  }

  public GetAuthorizationTokenValue(): string {
    let tokenValue = localStorage.getItem(BeyondEMRConstants.EncryptionTokenKey);
    if (tokenValue) {
      return (BeyondEMRConstants.TokenPrefixName + tokenValue);
    }

    return '';
  }

  public SetAuthorizationTokenValue(tokenValue): void {
    localStorage.setItem(BeyondEMRConstants.EncryptionTokenKey, tokenValue);
  }

  public RemoveAuthorizationTokenValue(): void {
    localStorage.removeItem(BeyondEMRConstants.EncryptionTokenKey);
  }

  getResult() {
    return this.result;
  }

  setResult(isActive: boolean) {
    this.result = isActive;
  }
}
