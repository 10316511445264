import { result } from "underscore";

export class RoutingHelper {

  public GetRoutingParamerterId(params: any, propertyName?: string): any {
    let routerParameter = 0;
    propertyName = propertyName ? propertyName : 'id';
    params.subscribe(params => {
      if (params[propertyName]) {
        routerParameter = params[propertyName];
      }
    });

    return routerParameter;
  }

  public GetFormType(route: any): any {
    return route.split('/')[0].toLowerCase();
  }

  public GetResetCode(route: any): any {
    if (route.split('/')?.length == 2) {
      return route.split('/')[1];
    }

    return null;
  }

  public EncodeParam(param: any): any {
    let encodedId = btoa(param);
    // let encodedId = param;
    return encodedId;
  }

  public DecodeParam(param: any): any {
    if (param) {
      let decodedId = atob(param);
      // let decodedId = param;
      return decodedId;
    }
    else {
      return param;
    }
  }
}
