import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
  ViewChild,
  Input
} from '@angular/core';
import { RightSidebarService } from '../../shared/services/rightsidebar.service';
import { ConfigService } from '../../shared/services/config.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BeyondEMRConstants } from '../../helpers/beyondemrconstants';
import { AuthService } from '@service/auth.sevice';
import { AuthTokenHelper } from '@helpers/auth-token-helper';
import { HttpBaseService } from '@service/http-base.service';
import { ReportParameter } from '@models/reportparameter/report-parameter.model';
import { BeyondEMRHelper } from '@helpers/beyondemr-helper';
import { fromEvent } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { PatientDetail } from '@models/appointments/patient.model';
import { RoutingHelper } from '@helpers/routinghelper';
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput') searchInput!: ElementRef;
  @Input() editActionUrl: string;
  public config: any = {};
  token: string;
  UserId: string;
  navClosed: boolean = true;
  orgCode: string = '';
  profileImage: any;
  shortProfileImage:any;
  getURL: string;
  dataItems: any[] | null;
  filteredPatients: any[] = [];
  search: string = '';
  isSidemenuCollapsed: boolean = false;
  searchText: string = '';
  public editActionURL: string = `patientfacesheet/`;
  allSuggestions: any[] = [];
  suggestions: any[] = [];
  showProfileImage: boolean = false;
  apiURL: string;
  isPatient: boolean = true;
  detectPatient: string;
  patients: string[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private dataService: RightSidebarService,
    private configService: ConfigService,
    private route: ActivatedRoute,
    private httpService?: HttpBaseService,
    private authHelper?: AuthTokenHelper,
    private authService?: AuthService,
    private router?: Router,
    private routingHelper?: RoutingHelper,
    private helper?: BeyondEMRHelper

  ) { }
  notifications: any[] = [
    {
      userImg: 'assets/images/user/user1.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user2.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user3.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    },
    {
      userImg: 'assets/images/user/user4.jpg',
      userName: 'Ashton Cox',
      time: '5 hours ago',
      message: 'Lets break for lunch...'
    },
    {
      userImg: 'assets/images/user/user5.jpg',
      userName: 'Sarah Smith',
      time: '14 mins ago',
      message: 'Please check your mail'
    },
    {
      userImg: 'assets/images/user/user6.jpg',
      userName: 'Airi Satou',
      time: '22 mins ago',
      message: 'Work Completed !!!'
    },
    {
      userImg: 'assets/images/user/user7.jpg',
      userName: 'John Doe',
      time: '3 hours ago',
      message: 'kindly help me for code.'
    }
  ];

  searchResults: any[] = [];
  ngOnInit() {
    this.config = this.configService.configData;
    this.token = this.authHelper.GetAuthorizationTokenValue();
    let tokenResult = this.authService.getDecodedAccessToken(this.token);
    this.detectPatient = this.authService.getDecodedAccessToken(this.token).UserRole;
    this.isPatient = this.detectPatient?.toLowerCase() == 'patient';    

    this.UserId = tokenResult['http://schemas.microsoft.com/ws/2008/06/identity/claims/primarysid'];
    this.httpService.getAPIURL().subscribe(url => {
      this.apiURL = `${url.API_URL}`
      this.getFormData();
      this.loadPatientGrid();
    });
    this.httpService.getAPIURL().subscribe(url => {
      this.apiURL = `${url.API_URL}`
      this.getURL = this.apiURL + 'patient/GetPatient/';
    });
  }
  onInputChange() {
    this.suggestions = this.allSuggestions.filter(item => {
      const formattedDOB = new Date(item.DOB).toLocaleDateString();
      return (
        item.FullName.toLowerCase().includes(this.searchText.toLowerCase()) ||
        formattedDOB.includes(this.searchText.toLowerCase()) ||
        item.PatientNumber.toString().includes(this.searchText.toLowerCase())
      );
    });
  }

  selectSuggestion(suggestion: any) {
    this.searchText = suggestion.FullName;
    let patientId = this.routingHelper.EncodeParam(suggestion.PatientId);
   
    window.location.href = "patientfacesheet/" + patientId;
  }

  loadPatientGrid() {
    if (!this.isPatient  && this.apiURL) {
      this.httpService.post(`${this.apiURL}patientDetail/GetAllPatientDetailList`, {}).subscribe(result => {
        this.dataItems = result;
        this.suggestions = this.dataItems.map(item => {
          return {
            FullName: item.FullName,
            DOB: new Date(item.DOB).toLocaleDateString(),
            PatientNumber: item.PatientNumber,
            PatientId: item.Id
          };
        });
        this.allSuggestions = this.suggestions;
      });
    }
  }


  // onSelectPatient(patient: any) {
  //   this.selectedPatient = patient;
  // }

  getFormData() {
    let url = this.apiURL + "reportparameter/GetReportParameter";
    this.httpService.get(url, null, true).subscribe(res => {
      if (res) {
        let data = res as ReportParameter;
        this.orgCode = data.Name;
        if (data.Logo && data.Logo != "") {
          this.profileImage = data.Logo;
        }
        if (data.ShortLogo && data.ShortLogo != "") {
          this.shortProfileImage = data.ShortLogo;
        }
        if (!this.profileImage) {
          this.showProfileImage = true;
        }
      }
    });
  }



  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(this.document.body, localStorage.getItem('menuOption'));
    } else {
      this.renderer.addClass(this.document.body, 'menu_' + this.config.layout.sidebar.backgroundColor);
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(this.document.body, localStorage.getItem('choose_logoheader'));
    } else {
      this.renderer.addClass(this.document.body, 'logo-' + this.config.layout.logo_bg_color);
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === "close") {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }

  }
  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }
  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = this.document.body.classList.contains(className);
    if (hasClass || this.navClosed == false) {
      this.navClosed = true;
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.navClosed = false;
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    this.isSidemenuCollapsed = !this.isSidemenuCollapsed;
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  public toggleRightSidebar(): void {
    this.dataService.changeMsg(
      (this.dataService.currentStatus._isScalar = !this.dataService
        .currentStatus._isScalar)
    );
  }

  public Logout(): void {
    this.helper.removeLocalStorage();
    this.router.navigate(['/authentication/signin']);
  }

}
