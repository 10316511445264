import { Injectable, forwardRef, NgModule } from '@angular/core'

import { HttpBaseService } from '../service/http-base.service';
import * as jwt_decode from 'jwt-decode'
import { AuthTokenHelper } from '../helpers/auth-token-helper';
import { Router } from '@angular/router';
import { BeyondEMRConstants } from '../helpers/beyondemrconstants';
import { HttpHeaders } from '@angular/common/http';
// import { CustomToasterService } from '../toaster/toaster.service';

@Injectable()
export class AuthService {
  apiURL: string = '';

  constructor(private router: Router, private httpService?: HttpBaseService) {
  }

  public IsTokenExpired(token?: any): boolean {
    if (!token) {
      token = new AuthTokenHelper().GetAuthorizationTokenValue();
    }
    let tokenResult = this.getDecodedAccessToken(token);
    let result: any;
    if (token) {
      const date = this.getTokenExpirationDate(token);
      result = !(date.valueOf() > new Date().valueOf());
    }
    if (result || result === undefined) {
      return true;
    }
    else {
      return false;
    }
  }

  getDecodedAccessToken(token: string): any {
    try {
      return jwt_decode(token);
    }
    catch (Error) {
      return null;
    }
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    if (decoded.exp === undefined) return null;

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  public Logout(): void {
    this.httpService.getAPIURL().subscribe(url => {
      this.apiURL = `${url.API_URL}auth/Login`;
    });

    let authTokenHelper = new AuthTokenHelper();
    let tokenValue = authTokenHelper.GetAuthorizationTokenValue();
    const data = new HttpHeaders()
      .set('Authorization', tokenValue);

    

    this.httpService.postWithHeader(`${this.apiURL}` + 'Auth/Logoff', null, data).subscribe(response => {
      this.router.navigate(['/authentication', 'signin']);
    });
  }
}
