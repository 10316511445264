import { BeyondEMRConstants } from '@helpers/beyondemrconstants'
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { Observable } from "rxjs";

@Injectable()
export class UserMenuAuth {

    public constructor() {       
    }

    GetUserMenu(): Array<any> {
        let tokenValue = localStorage.getItem("Menu");
        if (tokenValue) {
            return (JSON.parse(tokenValue));
        }

        return [];
    }

    SetUserMenu(menu: Array<any>): void {
        localStorage.setItem("Menu", JSON.stringify(menu));      
    }

    RemoveUserMenu(): void {
        localStorage.removeItem("Menu");
  }

  UserMenuAccess(screenName: string): any {
    let accessList = this.GetUserMenu();
    let itemToAccess = accessList.filter(item => item.ScreenName == screenName);

    if (itemToAccess && itemToAccess.length > 0) {
      return itemToAccess;
    }

    return null;
  }

    UserAccess(screenName: string, activityId: number): boolean {
        let accessList = this.GetUserMenu();
        let itemToAccess = accessList.find(item => item.ScreenName == screenName && item.ActivityMasterId == activityId);

        if (itemToAccess && itemToAccess.HasAccess == true) {
            return true;
        }

        return false;
    }
}
