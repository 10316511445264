import { Component } from '@angular/core';
import {
  Event,
  Router,
  NavigationStart,
  NavigationEnd,
} from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { PlatformLocation } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  currentUrl: string;
  public showHeader = false;
  constructor(public _router: Router, location: PlatformLocation, private spinner: NgxSpinnerService) {
    this.showHeader = this._router.url.includes('/authentication') || this._router.url.includes('/publicpatient');
    this._router.events.subscribe((routerEvent: Event) => {
      this.spinner.show();
      if (routerEvent instanceof NavigationStart) {
        location.onPopState(() => {
          window.location.reload();
        });
        this.currentUrl = routerEvent.url.substring(
          routerEvent.url.lastIndexOf('/') + 1
        );
      }
      if (routerEvent instanceof NavigationEnd) {
        this.showHeader = !this._router.url.includes('/authentication') && !this._router.url.includes('/publicpatient');
      }
      window.scrollTo(0, 0);
    });
  }
}
