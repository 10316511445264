import { NgModule, ModuleWithProviders } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from './common.service';
import { HttpBaseService } from './http-base.service';
import { AuthService } from './auth.sevice';

@NgModule({
  imports: [
  ],
  declarations: [
  ],
  providers: [
    DatePipe,
    CommonService,
    HttpBaseService,
    AuthService,
  ],
  exports: [
  ]
})

export class ServiceModule {
  static forRoot(): ModuleWithProviders<ServiceModule> {
    return { ngModule: ServiceModule };
  }
}
