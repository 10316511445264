import { Injectable, ElementRef, Renderer2 } from '@angular/core';
// import Swal from 'sweetalert2';
import { FormType } from '@helpers/dynamic-component-helper';

export class CommonService {

  constructor() {
  }

  private apiURL: any = null;
  private mlmObject: any = null;

  setApiURL(Input: any) {
    this.apiURL = Input;
  }

  getApiURL(): any {
    return this.apiURL;
  }

  setMLMObject(item: any) {
    this.mlmObject = item;
  }

  getMLMObject(): any {
    return this.mlmObject;
  }
  
  // START: GENERTIC HELPER
  getRandomNumber() {
    let text = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 16; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  getQueryString(field, url) {
    const href = url ? url : window.location.href;
    const reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    const str = reg.exec(href);
    return str ? str[1] : null;
  }

  toTrim(str) {
    if (!str) {
      return null;
    }
    return str.toString().trim();
  }

  getUserAction(type: FormType) {
    if (!type) {
      return null;
    }
    switch (type) {
      case 'Create':
        return 'A';
      case 'Edit':
        return 'U';
      case 'Delete':
        return 'D';
    }
  }

  getWorkflowStatus(type: FormType) {
    if (!type) {
      return null;
    }
    switch (type) {
      case 'Create':
        return 'I';
      case 'Edit':
      case 'Delete':
        return 'A';
    }
  }

  // remove all numberic character except dot
  removeNonNumericCharacter(str) {
    if (!str) {
      return 0;
    }
    const regex = /(?![0-9]|\.)./gm;
    const subst = ``;

    // The substituted value will be contained in the result variable
    const result = str.toString().replace(regex, subst);
    return result ? result : 0;
  }

  // remove comma(,) from string of currency
  // If currency look like "1,000.00" then the return output is "1000.00"
  convertCurrencyToDecimal(currency): string {
    if (!currency) {
      return null;
    }
    return currency.replace(/[^0-9.-]+/g, '');
  }
  // END: GENERTIC HELPER

  // START: DOM HELPER

  /**
   * Implement this methos to set the attribute name and value of an element in the DOM.
   * @param elementRef The element.
   * @param attributeName The attribute name.
   * @param attributeValue The new value.
   */
  setAttribute(renderer: Renderer2, elementRef: ElementRef, attributeName: string, attributeValue: string) {

    const element = this.getNativeElements(elementRef);

    renderer.setAttribute(element, attributeName, attributeValue);
  }

  /**
   * Implement this method to set the value of a property of an element in the DOM.
   * @param elementRef The element.
   * @param propertyName The property name.
   * @param propertyValue The new value.
   */
  setProperty(renderer: Renderer2, elementRef: ElementRef, propertyName: string, propertyValue: string) {

    const element = this.getNativeElements(elementRef);

    renderer.setProperty(element, propertyName, propertyValue);
  }

  /**
   * Implement this method to remove the attribute of an specific element in the DOM.
   * @param elementRef The element.
   * @param attributeName The removed attribute name.
   */
  removeAttribute(renderer: Renderer2, elementRef: ElementRef, attributeName: string) {

    const element = this.getNativeElements(elementRef);

    renderer.removeAttribute(element, attributeName);
  }

  /**
   * Implement this method to add the class attribute to an specific element in the DOM.
   * @param elementRef The element.
   * @param className The add class name.
   */
  addClass(elementRef: ElementRef | any, className: string) {

    const e = this.getElementWithValidClassList(elementRef);

    if (e) {
      e.classList.add(className);
    }
  }

  /**
   * Implement this method to add the class attribute for the parent elements of the passing elements the DOM.
   * @param elementRef The element.
   * @param className The add class name.
   */
  addClassWithParentElements(elementRef: ElementRef | any, className: string) {

    const e = this.getParentElemets(elementRef);

    if (e) {
      e.classList.add(className);
    }
  }

  /**
   * Implement this method to remove the class name of the element the DOM.
   * @param elementRef The element.
   * @param className The remove class name.
   */
  removeClass(elementRef: ElementRef | any, className: string) {

    const e = this.getElementWithValidClassList(elementRef);

    if (e) {
      e.classList.remove(className);
    }
  }

  /**
   * Implement this method to remove the class name for tha parent elements of passinf elements the DOM.
   * @param elementRef The element.
   * @param className The remove class name.
   */
  removeClassWithParentElements(elementRef: ElementRef | any, className: string) {

    const e = this.getParentElemets(elementRef);

    if (e) {
      e.classList.remove(className);
    }
  }

  /**
   * Implement this method to return the nativeElement from passing elements.
   * @param elementRef The element.
   */
  getNativeElements(elementRef: ElementRef) {
    const element = elementRef instanceof ElementRef ? elementRef.nativeElement : elementRef;

    return element;
  }

  /**
   * Implement this method to return the nativeElement from passing elements.
   * @param elementRef The element.
   */
  getDataTranferElements(event: any) {

    const dataTransfer = event && event.dataTransfer ? event.dataTransfer : null;

    return dataTransfer;
  }

  /**
   * Implement this method to return the nativeElement with clasList attribute
   * If classList in elementRef has not null then return "nativeElements" otherwise return "null".
   * @param elementRef The element.
   */
  getElementWithValidClassList(elementRef: ElementRef) {

    const e = this.getNativeElements(elementRef);

    if (e.classList !== undefined && e.classList !== null) {
      return e;
    }

    return null;
  }

  /**
   * Implement this method to return the nativeElement with clasList attribute
   * return parentElement of passing elements "elementRef"
   * @param elementRef The element.
   */
  getParentElemets(elementRef: ElementRef) {

    const element = this.getNativeElements(elementRef);

    return element ? element.parentElement : null;
  }
  // END: DOM HELPER
}
