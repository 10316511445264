import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from "@angular/router";
import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from '@angular/common';
import * as CryptoJS from 'crypto-js';

import { BeyondEMRConstants } from "@helpers/beyondemrconstants";
import { Observable } from "rxjs";

import { AuthService } from '@service/auth.sevice';
import { HttpBaseService } from "../service/http-base.service";
import { AuthTokenHelper } from "./auth-token-helper";
import { BeyondEMRHelper } from "./beyondemr-helper";

@Injectable()
export class AuthGuard implements CanActivate {
    private storageTokenName: string;
  private groupId: string;
  public apiURL: string;

  constructor(
    private httpService: HttpBaseService,
    private authHelper: AuthTokenHelper,
    private authorizationService: AuthService,
    private router: Router,
    private helper: BeyondEMRHelper,
    @Inject(PLATFORM_ID) private _platformId: Object
  ) {
    this.httpService.getAPIURL().subscribe(url => {
      this.apiURL = `${url.API_URL}`;      
    });
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any{
      if (isPlatformBrowser(this._platformId)) {       
          let token = localStorage.getItem(BeyondEMRConstants.EncryptionTokenKey);
          if (this.authorizationService.IsTokenExpired(token)) {               
            this.router.navigate(['/authentication/signin']);
                //return null;
            }
            else {
            let url = state.url;
            if (url.split("/edit/").length > 1) {
              url = url.split("/edit/")[0] + "/edit";
            }
            //let result = ;
            return this.authenticate(url);
          }
        }
      // not logged in so redirect to login page with the return url
      this.helper.showNotification("Authentication Expired. Please login to continue.", "snackbar-danger");
      this.router.navigate(['/authentication/signin']);
        //return false;
  }

  authenticate(url): any {
    let data = true;
    this.httpService.getAPIURL().subscribe(urlnew => {
      this.apiURL = `${urlnew.API_URL}`;
      if (this.apiURL) {
        this.httpService.get(this.apiURL + "auth/GetAuthMethod?url=" + url, null, true).subscribe(res => {
          data = res as any;

          this.authHelper.setResult(data);
          if (data == false) {
            this.router.navigate(['/authentication/unautherized']);
          }
          return data;
        });
      }
    });
    
    return data;
  }
}
