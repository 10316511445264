import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { HomeComponent } from './home/home.component';
import { CounterComponent } from './counter/counter.component';
import { FetchDataComponent } from './fetch-data/fetch-data.component';

import { PageLoaderComponent } from './layout/page-loader/page-loader.component';
import { HeaderComponent } from './layout/header/header.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { RightSidebarComponent } from './layout/right-sidebar/right-sidebar.component';
import { DynamicScriptLoaderService } from './shared/services/dynamic-script-loader.service';
import { RightSidebarService } from './shared/services/rightsidebar.service';
import { ConfigService } from './shared/services/config.service';

import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMaskModule } from 'ngx-mask';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ServiceModule } from './service/service.module';
import { AuthTokenHelper } from './helpers/auth-token-helper';
import { RoutingHelper } from './helpers/routinghelper';
import { BeyondEMRHelper } from './helpers/beyondemr-helper';
import { UserMenuAuth } from './helpers/usermenuauth';
import { AuthGuard } from './helpers/auth.guard';

import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatRadioModule } from '@angular/material/radio';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DragulaModule, DragulaService } from 'ng2-dragula';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    HeaderComponent,
    SidebarComponent,
    RightSidebarComponent,
    HomeComponent,
    CounterComponent,
    FetchDataComponent,
    PageLoaderComponent,
  ],
  exports: [MatSidenavModule, MatFormFieldModule, MatInputModule, MatSliderModule],
  imports: [
    BrowserModule.withServerTransition({ appId: 'beyond-therapy-emr' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    NgxSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatAutocompleteModule,
    ServiceModule,
    NgxMaskModule,
    ColorPickerModule,
    MatSidenavModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatButtonModule,
    NgxDatatableModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatCheckboxModule,
    MatDialogModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSnackBarModule,
    DragDropModule,
    FormsModule,
    MatSidenavModule,
    MatRadioModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    ScrollingModule,
    MatSliderModule,
    DragulaModule,
    NgbModule,
    RouterModule.forRoot([
      {
        path: 'authentication',
        loadChildren: () =>
          import('./authentication/authentication.module').then(
            m => m.AuthenticationModule
          )
      },
      {
        path: 'patients',
        loadChildren: () =>
          import('./pages/patient/patient-main.module').then(m => m.PatientMainModule)
      },
      {
        path: 'publicpatient',
        loadChildren: () =>
          import('./public/patient/public-patient.module').then(m => m.PublicPatientModule)
      },
      {
        path: 'report',
        loadChildren: () =>
          import('./pages/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'userprofile',
        loadChildren: () =>
          import('./pages/userprofile/user-profile.module').then(m => m.UserProfileModule)
      },
      { path: '', redirectTo: ('authentication/signin'), pathMatch: 'full' },
      {
        path: 'patient-dashboard',
        loadChildren: () =>
          import('./pages/patient-dashboard/patient-dashboard.module').then(m => m.PatientDashboardModule)
      },
      {
        path: 'stripe-integration',
        loadChildren: () =>
          import('./pages/stripe-integration/stripe-integration.module').then(m => m.StripeIntegrationModule)
      },
    ]),
    NgbModule
  ],
  providers: [
    DynamicScriptLoaderService,
    ConfigService,
    RightSidebarService,
    AuthTokenHelper,
    RoutingHelper,
    BeyondEMRHelper,
    UserMenuAuth,
    AuthGuard,
    DragulaService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
})
export class AppModule { }
