import { Injectable } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BeyondEMRConstants } from './beyondemrconstants';

@Injectable()
export class BeyondEMRHelper {
  pagePerView = 10;
  activePage: number = 1;
  pageNumber: number = 1;

  public constructor(
    private datePipe?: DatePipe,
    private snackBar?: MatSnackBar,
  ) {
  }

  filterByMaster(id, master, filterName?, idColumn?): string {
    if (id) {
      filterName = filterName ? filterName : 'Name';
      idColumn = idColumn ? idColumn : 'Id';
      var result = master?.find(x => x[idColumn] == id);
      return result ? result[filterName] : '';
    }
    return '';
  }

  findMultiselectMaster(entity, master, filtername): string {
    if (entity?.length > 0) {
      let result = '';
      for (let i = 0; i < entity?.length; i++) {
        let lang = master?.find(j => j.Id == entity[i][filtername])?.DisplayName;
        if (lang) {
          result += ', ' + lang;
        }
      }
      result = result?.replace(/(^[,\s]+)|([,\s]+$)/g, '');
      return result;
    }
    return '';
  }

  formatPhoneNumber(str): string {
    let cleaned = ('' + str).replace(/\D/g, '');
    let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      let intlCode = (match[1] ? '+1 ' : '+1 ')
      return [intlCode, match[2], '-', match[3], '-', match[4]].join('')
    }
    return '';
  }

  getAgeFromDOB(DOB) {
    if (DOB) {
      let timeDiff = Math.abs(Date.now() - new Date(DOB).getTime());
      let result = Math.floor(timeDiff / (1000 * 3600 * 24) / 365.25);
      return result + " years";
    }
    return "0 years";
  }

  bindAddress(contact, masters): string {
    let result = '';
    if (contact != null) {
      result = contact['Suite'] != '' ? 'Suite: ' + contact['Suite'] + ', ' : '';
      result += contact['Address'] != '' ? contact['Address'] + '<br \>' : '';
      result += contact['CityId'] > 0 ? this.filterByMaster(contact['CityId'], masters['City']) + ', ' : '';
      result += contact['StateId'] > 0 ? this.filterByMaster(contact['StateId'], masters['State']) + ', ' : '';
      result += contact['CountryId'] > 0 ? this.filterByMaster(contact['CountryId'], masters['Country']) + ' - ' : '';
      result += contact['ZipId'] > 0 ? this.filterByMaster(contact['ZipId'], masters['ZipCode']) :  '';
    }

    return result;
  }

  formatDate(str, format): string {
    format = format ? format : 'MM/dd/yyyy';
    const formattedValue = str ? this.datePipe.transform(str, format) : null;
    if (formattedValue) {
      return formattedValue;
    }
    return '';
  }

  findMultiselectModiferDiagnosis(entity, master, filtername): string {
    if (entity?.length > 0) {
      let result = '';
      for (let i = 0; i < entity?.length; i++) {
        let lang = master?.find(j => j.Id == entity[i][filtername])?.Code;
        if (lang) {
          result += ', ' + lang;
        }
      }
      result = result?.replace(/(^[,\s]+)|([,\s]+$)/g, '');
      return result;
    }
    return '';
  }

  showNotification(text, colorName: any = 'snackbar-success', placementFrom: any = 'bottom', placementAlign: any = 'center') {
    this.snackBar.open(text, '', {
      duration: 2000,
      verticalPosition: placementFrom,
      horizontalPosition: placementAlign,
      panelClass: colorName,
    });
  }

  bindMaster(master, isCreate, masterId, idName = 'Id') {
    let output = master;
    if (master && master.length > 0) {
      if (isCreate) {
        output = output?.filter(x => x['Active'] == true);

        if (output && output.length > 0 && output[0]?.DisplayInList == undefined) {
          output = output;
        }
        else if (output && output.length > 0 && output[0].DisplayInList != undefined) {
          output = output?.filter(x => x['DisplayInList'] == true);
        }
      }
      else {

        output = output?.filter(x => x['Active'] == true || x[idName] == masterId);

        if (output && output.length > 0 && output[0].DisplayInList != undefined) {
          output = output?.filter(x => x['DisplayInList'] == true || x[idName] == masterId);
        }
      }
    }

    return output;
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement = document.querySelector('.ng-invalid:not(form)');

    if (firstInvalidControl) {
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth"
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  public decodedId() {
    let decrypt = localStorage.getItem("EncryptId");
    let decodedId = atob(decodeURIComponent(decrypt));
    return decodedId;
  }

  public removeLocalStorage() {
    localStorage.removeItem(BeyondEMRConstants.EncryptionTokenKey);
    localStorage.removeItem("Menu");
    localStorage.removeItem("EncryptId");
    localStorage.removeItem("CalendarFilter");
    localStorage.removeItem("claimfilter");
    localStorage.removeItem("encounterfilter");
    localStorage.removeItem("providerdashboardfilter");
    localStorage.removeItem("paymentreportfilter");
    localStorage.removeItem("erafilter");
    localStorage.removeItem("PersonId");
    localStorage.removeItem("StatusId");
    localStorage.removeItem("appointmentfilter")
  }

  activePageNumber(activePageNumber, pageNumber) {
    this.activePage = activePageNumber;
    if (pageNumber != activePageNumber) {
      this.pageNumber = activePageNumber;
      return (activePageNumber - 1) * this.pagePerView;
    }
  }
}
